import logo2 from './skf-logo.svg';
import './App.css';
import './media.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import moment from 'moment';
import trackVisitor from './utilities/tracker';
import { useEffect } from 'react';

function App() {

  let currentDate = new Date();
  currentDate = 'Updated on:' + moment(currentDate).format("DD-MM-YYYY LTS") + ' IST.'

  useEffect(() => {
    trackVisitor(); // Call the tracking function when the component mounts
  }, []);
  return (
    <div className="section-1">

      <a href="/">
        <img src={logo2} id="logo-img" title="Satta King Bazar Daily Results And Full Monthly Chart" alt="Satta-King-Fast.com | Super Fast Satta Results and Monthly Chart of October 2022 for Gali, Desawar, Ghaziabad and Faridabad" />
      </a>

      <p className="s1-p1">
        Daily Superfast Satta King Result of October 2022 And Leak Numbers for Gali, Desawar, Ghaziabad and Faridabad With Complete Satta King 2019 Chart And Satta King 2018 Chart From Satta King Fast, Satta King Fast Result, Satta King Desawar 2019, Satta King
        Desawar 2018.
      </p>
      <div className="container-1">



        <p className="s1-p2">
          Satta-King-Fast.com is most populer gaming discussion forum for players to use freely and we are not in partenership with any gaming company.
        </p>



        <p className="s1-p3">
          कृपया ध्यान दें हमारे नेटवर्क की किसी भी वेबसाईट पर लीक गेम बेचने या खाईवाली से संबंधित कोई भी विज्ञापन नहीं डाला जाता है इसलिए जो भी व्यक्ति लीक गेम बेचने का या खाईवाली का विज्ञापन डलवाना चाहते हैं वह बिल्कुल भी संपर्क न करें -धन्यवाद
        </p>



        <p className="s1-p4">
          नमस्कार दोस्तों, हमारी जानकारी मे आया है की कुछ लोग हमारे नाम पर आपसे लीक नंबर देने के लिए पैसे लेकर आपके साथ ठगी कर रहे है कृपया ऐसे लोगों से सावधान रहें हम कभी भी गेम के लीक के लिए किसी प्रकार से पैसे नहीं लेते है न अड्वान्स मे और ना गेम पास होने के
          बाद। ये जो भी सट्टा किंग, Queen, MD, CEO और मुंशी बने बैठे है इनकी दो कौड़ी की औकात नहीं है इसलिए बेकार मे इनपर अपनी मेहनत का पैसा न लुटाए -धन्यवाद
        </p>



      </div>
      <p className="s1-p5" id='currentDate'>{currentDate}</p>
    </div>
  );
}

export default App;
