import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import SectionTable from './sectionTable';
import moment from 'moment';
import { Table } from 'antd';


let monthYear
function MonthlySatta({ message, dateData }) {

  // console.log(message,"===============")
  const [data, setData] = useState([]);
  const [monthSndYear, setMonth] = useState([]);
  const [columns, setcolumn] = useState([]);
  useEffect(() => {
    if (message) {
      setData(message)
    }
    if(dateData?.date){
      setMonth(dateData?.date)
    }
    
  })
  useEffect(()=>{
    if(data.length>0){
      // console.log(data)
      let array=Object.keys(data[0])
      for (let i = 0; i < array.length; i++) {
        array[i]={ title: array[i]=='day'?'Day':array[i], dataIndex: array[i], key: array[i] }
        
      }
      // console.log(array)
      setcolumn(array)
    }
  },[data])


  function getDaysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }


  const currentYear = new Date().getFullYear();
  let arr = []
  for (let year = currentYear - 9; year <= currentYear; year++) {
    arr.push(year)
  }



  var date = new Date();
  var currentMonthDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  // console.log(currentMonthDays)
  var getCurrentYear = date.getFullYear()
  // console.log(getCurrentYear)

  // daysInThisMonth()


  let numOfDays = getDaysInMonth(getCurrentYear, currentMonthDays - 1)
  // console.log(numOfDays)
  let monthDays = []

    
  useEffect(() => {
     monthYear = new Date()
    monthYear = "" + moment(monthYear).format('MMM-yyyy')
    setMonth(monthYear)
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({
        month: date.getMonth() + 1,
        year: getCurrentYear,
        gameName: "",
        result: "",
        days: numOfDays,
      }),
    })
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => console.error(error));
  }, []);
  return (
    <div className="section-3">
      <div className="header-2 table-responsive scrollbar" id="scrollbar1">
        <h5>Monthly Satta King Result Chart of {monthSndYear}</h5>
        {/* <h1>Desawer, Gaziabad and Faridabad</h1> */}
      </div>
      <div className='table-responsive' id="scrollbar1">
        <Table columns={columns} dataSource={data} rowKey="day" pagination={false} />
      </div>

    </div>
  )
}
export default MonthlySatta;
